// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import * as Routes_Location from "../../../routes/common/Routes_Location.res.js";
import * as Routes_Services from "../../../routes/common/Routes_Services.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as TopServicesScss from "./TopServices.scss";
import * as Routes_Marketplace from "../../../routes/common/Routes_Marketplace.res.js";

var css = TopServicesScss;

var topServices = [
  {
    title: "Data Center Locations",
    slug: Routes_Location.index
  },
  {
    title: "Colocation RFP",
    slug: Routes_Project.Colocation.$$new
  },
  {
    title: "Bare Metal RFP",
    slug: Routes_Project.BareMetal.$$new
  },
  {
    title: "Cloud RFP",
    slug: Routes_Project.Cloud.$$new
  },
  {
    title: "Bare Metal IaaS",
    slug: Routes_Services.BareMetal.index
  },
  {
    title: "Deploy Multicloud",
    slug: Routes_Services.Multicloud.index
  },
  {
    title: "Colocation Marketplace",
    slug: Routes_Marketplace.index
  }
];

function TopServices(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Top Services",
                      className: css.header
                    }),
                JsxRuntime.jsx("ul", {
                      children: Belt_Array.map(topServices, (function (service) {
                              var slug = service.slug;
                              if (slug === "all-resources") {
                                return null;
                              } else {
                                return JsxRuntime.jsx("li", {
                                            children: JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(A.make, {
                                                        href: service.slug,
                                                        children: JsxRuntime.jsx("span", {
                                                              children: service.title,
                                                              className: css.serviceName
                                                            })
                                                      }),
                                                  className: css.listItemWrapper
                                                }),
                                            className: css.li
                                          }, service.slug);
                              }
                            })),
                      className: css.ul
                    })
              ],
              className: css.section
            });
}

var make = TopServices;

export {
  css ,
  topServices ,
  make ,
}
/* css Not a pure module */
