// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H4 from "../../../../styleguide/components/Heading/H4.res.js";
import * as Form from "../../../../styleguide/forms/Form/Form.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Api_BlogPost from "../../../../api/blog-posts/Api_BlogPost.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as SubscribeImage from "../../../common/subscribe/SubscribeImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogNewsletterData from "./BlogNewsletterData.res.js";
import * as BlogNewsletterScss from "./BlogNewsletter.scss";
import * as BlogNewsletterEmailError from "./errors/BlogNewsletterEmailError.res.js";

var css = BlogNewsletterScss;

function orientationClassName(orientation, borderRadius) {
  var tmp;
  tmp = orientation === "Row" ? css.rowOrientation : css.columnOrientation;
  var tmp$1;
  tmp$1 = borderRadius === "Flat" ? css.flatBorder : css.roundBorder;
  return Cx.cx([
              css.section,
              tmp,
              tmp$1
            ]);
}

var initialState_input = BlogNewsletterData.Input.empty();

var initialState = {
  isSubmitted: false,
  input: initialState_input,
  results: undefined,
  resultMessage: undefined
};

function BlogNewsletter(props) {
  var __borderRadius = props.borderRadius;
  var __orientation = props.orientation;
  var orientation = __orientation !== undefined ? __orientation : "Row";
  var borderRadius = __borderRadius !== undefined ? __borderRadius : "Flat";
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "Update") {
              return {
                      TAG: "Update",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: action._0,
                        results: state.results,
                        resultMessage: state.resultMessage
                      }
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: state.results,
                        resultMessage: action._0
                      },
                      _1: (function (param) {
                          param.dispatch("AfterSubmit");
                        })
                    };
            }
          }
          switch (action) {
            case "AfterSubmit" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: false,
                          input: state.input,
                          results: state.results,
                          resultMessage: state.resultMessage
                        }
                      };
            case "Validate" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: BlogNewsletterData.Validate.all(state.input),
                          resultMessage: state.resultMessage
                        }
                      };
            case "Send" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: BlogNewsletterData.Validate.all(state.input),
                          resultMessage: state.resultMessage
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            var state = param.state;
                            if (BlogNewsletterData.Validate.valid(Belt_Option.getExn(state.results))) {
                              return $$Promise.wait(Api_BlogPost.subscribeBlogNewsletter(state.input), (function (x) {
                                            if (x.TAG === "Ok") {
                                              return dispatch({
                                                          TAG: "ShowResultMessage",
                                                          _0: x._0.message
                                                        });
                                            }
                                            SentryLogger.error1({
                                                  rootModule: "BlogNewsletter",
                                                  subModulePath: /* [] */0,
                                                  value: "make",
                                                  fullPath: "BlogNewsletter.make"
                                                }, "BlogNewsletter::Error", [
                                                  "Error",
                                                  x._0
                                                ]);
                                            dispatch("AfterSubmit");
                                          }));
                            } else {
                              return dispatch("AfterSubmit");
                            }
                          })
                      };
            case "BeforeSubmit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: true,
                          input: state.input,
                          results: state.results,
                          resultMessage: state.resultMessage
                        },
                        _1: (function (param) {
                            param.dispatch("Send");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var message = state.resultMessage;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(SubscribeImage.make, {
                                    className: css.icon
                                  }),
                              JsxRuntime.jsx(H4.make, {
                                    className: css.header,
                                    children: "Subscribe to Our Newsletter to Receive All Posts in Your Inbox!"
                                  })
                            ],
                            className: css.leftContent
                          }),
                      JsxRuntime.jsx("div", {
                            children: message !== undefined ? JsxRuntime.jsx("div", {
                                    children: message,
                                    className: css.resultMessage
                                  }) : JsxRuntime.jsxs(Form.make, {
                                    className: css.form,
                                    onSubmit: (function (param) {
                                        dispatch("BeforeSubmit");
                                      }),
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "newsletter--email",
                                                    value: state.input.email,
                                                    placeholder: "Email",
                                                    status: Belt_Option.map(state.results, (function (x) {
                                                            if (x.email.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    className: css.email,
                                                    onChange: (function ($$event) {
                                                        dispatch({
                                                              TAG: "Update",
                                                              _0: {
                                                                email: $$event.target.value
                                                              }
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(BlogNewsletterEmailError.make, {
                                                    results: state.results
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: "SM",
                                            color: "Teal",
                                            expanded: true,
                                            busy: state.isSubmitted,
                                            submit: true,
                                            className: css.submitButton,
                                            children: "Subscribe"
                                          })
                                    ]
                                  }),
                            className: css.rightContent
                          })
                    ],
                    className: css.container
                  }),
              className: orientationClassName(orientation, borderRadius)
            });
}

var Input;

var Validate;

var ValidationResult;

var make = BlogNewsletter;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  orientationClassName ,
  initialState ,
  make ,
}
/* css Not a pure module */
