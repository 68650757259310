// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function SubscribeImage(props) {
  return JsxRuntime.jsxs(Svg.make, {
              title: "Subscribe",
              viewBoxWidth: "107",
              viewBoxHeight: "79",
              className: props.className,
              children: [
                JsxRuntime.jsx("defs", {
                      children: JsxRuntime.jsx("path", {
                            id: "a",
                            d: "M13 8h90a5 5 0 0 1 5 5v62a5 5 0 0 1-5 5H13a5 5 0 0 1-5-5V13a5 5 0 0 1 5-5z"
                          })
                    }),
                JsxRuntime.jsxs("g", {
                      children: [
                        JsxRuntime.jsx("path", {
                              d: "M0 0h116v88H0z"
                            }),
                        JsxRuntime.jsx("mask", {
                              children: JsxRuntime.jsx("path", {
                                    id: "a",
                                    d: "M13 8h90a5 5 0 0 1 5 5v62a5 5 0 0 1-5 5H13a5 5 0 0 1-5-5V13a5 5 0 0 1 5-5z"
                                  }),
                              id: "b",
                              fill: "#fff"
                            }),
                        JsxRuntime.jsx("path", {
                              id: "a",
                              d: "M13 8h90a5 5 0 0 1 5 5v62a5 5 0 0 1-5 5H13a5 5 0 0 1-5-5V13a5 5 0 0 1 5-5z",
                              fill: "#7ACCC8",
                              fillRule: "nonzero"
                            }),
                        JsxRuntime.jsx("path", {
                              d: "M8 8h100v15L63.55 49.894a10 10 0 0 1-10.29.038L8 23V8z",
                              fill: "#099891",
                              fillRule: "nonzero",
                              mask: "url(#b)"
                            }),
                        JsxRuntime.jsx("path", {
                              d: "M105 21c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z",
                              fill: "#EDFBFA",
                              fillRule: "nonzero"
                            }),
                        JsxRuntime.jsx("circle", {
                              cx: "105",
                              cy: "11",
                              fill: "#D70000",
                              fillRule: "nonzero",
                              r: "8"
                            })
                      ],
                      fill: "none",
                      fillRule: "evenodd",
                      transform: "translate(-8 -1)"
                    })
              ]
            });
}

var make = SubscribeImage;

export {
  make ,
}
/* Svg Not a pure module */
